<template>
  <el-dialog title="客户信息匹配" :close-on-click-modal="false" :visible.sync="dialogVisible" width="50%" @close="handleClose">
    <el-card>
      <div>
        <el-button type="primary" @click="downloadFile">导出模版</el-button>
        <el-button type="primary" @click="handleImport">导入</el-button>
        <el-button type="primary" @click="matchCustomer">匹配</el-button>
      </div>
      <div class="background">
        <div class="result">匹配结果</div>
        <el-table
          :data="tableData"
          style="width: 100%">
          <el-table-column label="系统数据">
            <el-table-column
              prop="systemMatchingName"
              label="客户姓名">
            </el-table-column>
            <el-table-column
              prop="systemMatchingPhone"
              label="客户电话">
            </el-table-column>
            <el-table-column
              prop="systemMatchingTeam"
              label="系统客户团队">
            </el-table-column>
            <el-table-column
              prop="systemMatchingProduct"
              label="系统客户产品">
            </el-table-column>
          </el-table-column>
          <el-table-column label="导入数据">
            <el-table-column
              prop="matchingName"
              label="客户姓名">
            </el-table-column>
            <el-table-column
              prop="matchingPhone"
              label="客户电话">
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button @click="reportFile">导出</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
    <uploadFile :url="url" @closeUpload="closeUpload" v-show="isShowUpload"></uploadFile>
  </el-dialog>
</template>

<script>
import uploadFile from '@/views/customerManagement/component/uploadFile'
import { postRequest } from '@/api' 
import {filedownaction, fileaction} from 'src/utils/cities'
export default {
  data() {
    return {
      dialogVisible: true,
      tableData: [],
      tableData1: [],
      isShowUpload: false,
      code: '',
      url: '',
      // downloadHref: fileaction+ `/customer/uploadCustomerExcel?matchingCode=${this.code}&computerToken=${JSON.parse(localStorage.getItem('newToken')).computerToken}`,
      downloadModel: filedownaction + '?type=customerMatch'
    }
  },
  components: { uploadFile },
  methods: {
    handleClose() {
      this.$emit('closeImport')
    },
    handleImport(){
      console.log(123)
      this.url = '/customer/parseCustomerExcel'
      this.isShowUpload = true
    },
    closeUpload(val){
      console.log(val)
      this.code = val
      this.isShowUpload = false
    },
    downloadFile(){
      window.open(filedownaction + '?type=customerMatch')
    },
    reportFile(){
      // let data = {
      //   matchingCode: this.code
      // }
      // postRequest('/customer/uploadCustomerExcel',data).then(res=>{
      //   console.log(res)
      // })
      window.open(fileaction+ `/customer/uploadCustomerExcel?matchingCode=${this.code}&computerToken=${JSON.parse(localStorage.getItem('newToken')).computerToken}`)
    },
    matchCustomer(){
      let data = {
        matchingCode: this.code
      }
      postRequest('/customer/matchingCustomerInfo', data).then(res=>{
        console.log(res, '匹配结果')
        let arr = []
        res.map(item => {
          if (item.matchingPhone == item.systemMatchingPhone) {
            arr.push(item)
          }
        })
        if (arr.length <= 0) {
          this.$message({
            message: '无匹配数据',
            type: 'warning'
          })
        } else {
          this.$message({
            message: `数据匹配${arr.length}条`,
            type: 'success'
          })
        }
        this.tableData = res
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.result {
  width: 100%;
  padding: 5px;
  text-align: center;
  border: 1px solid #EEF1FC;
  margin-top: 10px;
  color: #999;
}
.background {
  background: #EEF1FC;
}
.data {
  display: flex;
}
.cell {
  text-align: center !important;
}
.buttons-1 {
  display: flex;
  >div {
    padding: 5px 20px;
    background: #eef1ff;
    color: #5967ff;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #5967ff;
    font-weight: 500;
    a {
      color: #5967ff;
    }
  }
}
.el-card {
  margin-top: -20px;
}
.downloadHref {
  text-decoration: none;
  color: #999;
}
.downloadModel {
  text-decoration: none;
  color: #fff;
}
</style>
