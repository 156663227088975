<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="form" class="demo-form-inline searchFrom" size="small">
            <el-form-item label="姓名">
              <el-input
                v-model="form.name"
                :clearable="true"
                placeholder="请输入客户姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="电话">
              <el-input
                v-model="form.phone"
                :clearable="true"
                placeholder="请输入客户电话"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" :disabled="disabledSearch" @click="getAllInfo(1)">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain @click="importInfo">导入匹配</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card shadow="always" class="card">
      <div class="Selectnum">已选中 <span style="color:#5479FF">{{multipleSelection.length}}</span> 条信息</div>
      <div class="table-block">
        <el-table v-loading="loading" :data="tableData" :header-cell-style="{'text-align': 'center', 'background': '#EEF1FC', 'color': '#656565'}" :cell-style="{'text-align':'center'}" style="width: 100%" @selection-change="handleSelectionChange">
          <!-- <el-table-column type="selection" width="50"></el-table-column> -->
          <el-table-column prop="customerName" label="客户姓名"> </el-table-column>
          <el-table-column prop="productName" label="会员产品"> </el-table-column>
          <el-table-column prop="tel" label="手机号"> </el-table-column>
          <el-table-column prop="integral" width="80" label="积分"> </el-table-column>
          <el-table-column prop="customerProductVos[0].endTime" label="会员到期时间"> </el-table-column>
          <el-table-column prop="vipCode" label="会员号"> </el-table-column>
          <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button @click="addNew(scope.row, 2)" type="text" size="small">详情</el-button>
          </template>
        </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
        >
      </el-pagination>
    </el-card>
    <matchDetail :info="info" v-if="showDetail" @closeDetail="closeDetail"></matchDetail>
    <importCustomerInfo v-if="showImportInfo" @closeImport="closeImport"></importCustomerInfo>
  </div>
</template>

<script>
import importCustomerInfo from '@/views/customerManagement/component/importCustomerInfo'
import matchDetail from '@/views/customerManagement/component/matchDetail'
import { postRequest } from '@/api'
export default {
  data() {
    return {
      form: {
        name: '',
        phone: '',
      },
      showImportInfo: false,
      tableData: [],
      multipleSelection: [],
      showDetail: false,
      info: {},
      loading: true,
      disabledSearch: false,
      pageNo: 1,
      pageSize: 10,
      total: null
    }
  },
  components: { importCustomerInfo, matchDetail },
  mounted(){
    this.getAllInfo()
  },
  methods: {
    importInfo() {
      this.showImportInfo = true
    },
    closeImport() {
      this.showImportInfo = false
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.getAllInfo()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val
      this.getAllInfo()
    },
    closeDetail(){
      this.showDetail = false
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    addNew(val){
      console.log(val)
      this.info = val
      this.showDetail = true
    },
    getAllInfo (no) {
      this.disabledSearch = true
      this.loading = true
      let data = {
        tel: this.form.phone ? this.form.phone : '',
        customerName: this.form.name ? this.form.name : '',
        pageNo: no ? no : this.pageNo,
        pageSize: this.pageSize
      }
      postRequest('/customer/queryCustomerPage', data).then(res => {
        console.log(res)
        this.tableData = res.data
        this.total = res.count
        this.loading = false
        this.disabledSearch = false
      }).catch(()=>{
        this.loading = false
        this.disabledSearch = false
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}
// .el-card {
//   margin-top: 26px;
// }
.Selectnum{
  padding:6px 30px;
  border:1px solid #BFCBF4;
  font-size: 12px;
  color:#666;
  border-radius: 5px;
  margin-bottom:10px;
}
</style>
