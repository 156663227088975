<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="35%"
    :show-close="false"
    @close="handleClose">
    <el-card class="cardDetail">
      <div class="detailInfo">
        <div class="pic">
          <img :src="info1.customPicUrl ? info1.customPicUrl : 'https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/4FE9D265B1EF406FA50B208FE59A6A34.png'" alt="">
        </div>
        <div class="info">
          <div>
            <div>姓名:&emsp;</div><div>{{info1.customerName}}</div>
          </div> 
          <div>
            <div>性别:&emsp;</div><div>{{info1.xingbie}}</div>
          </div>
          <div>
            <div>电话:&emsp;</div><div>{{info1.telephone}}</div>
          </div>
          <div>
            <div>证件号:&emsp;</div><div>{{info1.identifycationNo}}</div>
          </div>
          <div>
            <div>证件类型:&emsp;</div><div>{{info1.identificationName}}</div>
          </div>
        </div>
      </div>
    </el-card>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: true,
      info1: {},
      form: {}
    }
  },
  props:['info'],
  mounted(){
    console.log(this.info)
    this.$nextTick(()=>{
      if (this.info.sex == '1') {
        this.info.xingbie = '男'
      } else if (this.info.sex == '2') {
        this.info.xingbie = '女'
      } else if (this.info.sex == '0') {
        this.info.xingbie = '未知'
      }
      if (this.info.identificationType == 1) {
        this.info.identificationName = '身份证'
      }
      this.info1 = this.info
    })
  },
  methods: {
    handleClose(){
      this.$emit('closeDetail')
    }
  }
}
</script>

<style lang="scss" scoped>
.detailInfo {
  margin: 0 auto;
  width: 280px;
  .pic {
    width: 250px;
    text-align: center;
    img {
      width: 90px;
      height: 90px;
      border-radius: 50%;
    }
  }
  // :nth-child(2) {
  //   margin-left: 30px;
  //   div {
  //     margin-top: 20px;
  //     font-size: 16px;
  //   }
  // }
}
.title-1 {
  color: #444444;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 0;
}
.el-card {
  margin-top: -20px;
}
.info {
  color: #676767;
  >div {
    margin-top: 20px;
    font-size: 16px;
    display: flex;
    :first-child {
      width: 100px;
      text-align: right;
    }
    :last-child {
      flex: 1;
    }
  }
}
</style>